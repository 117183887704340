<template>
    <div id="mail">
        <div v-if="isLoading">Loading...</div>
        <div v-else-if="!isError" class="block">
            <header class="mail-header">
                <h1 class="text-secondary mail-title">{{ worksites.title_ja }}<br></h1>
                <p class="mail-title-en text-secondary">{{ worksites.title_en }}</p>
            </header>
            <div class="mail-content">
                <p class="mail-text">
                    {{ worksites.short_summary_ja }}<br>
                    {{ worksites.short_summary_en }}
                </p>
                <p class="mail-text mt-4">
                    <strong>Time（UTC）：</strong><br>
                    開始日時（Begin）：{{ worksitesDatetime(worksites.begin_date) }}<br>
                    終了日時（End）：{{ worksitesDatetime(worksites.end_date) }}<br>
                    公開日時（Lastupdate）：{{ worksitesDatetime(worksites.update_date) }}
                </p>
            </div>
            <div class="mail-location">
                <div class="mail-location-header">
                    <h2 class="text-secondary mail-location-title">影響拠点一覧<br></h2>
                    <p class="mail-location-title-en text-secondary">Affected Location(s)</p>
                </div>
                <table class="table table-striped align-middle mb-0 table_sticky mail-location-table">
                    <tbody>
                    <template v-for="(row, index) in worksites.worksites" :key="index">
                        <tr>
                            <td class="text-center" width="30">{{ index + 1 }}</td>
                            <td>{{ row }}</td>
                        </tr>
                    </template>
                    </tbody>
                </table>
            </div>
        </div>
        <div v-else id="error">
            <div class="error-content">
                <div v-if="errorMessage.ja">
                    <h1 class="error-title">{{ errorMessage.ja }}</h1>
                    <h2 class="error-sub-title">{{ errorMessage.en }}</h2>
                </div>
                <div v-else>
                    <h1 class="error-title">システムエラーが発生しました。</h1>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import {reactive, ref} from 'vue';
import {useRouter} from 'vue-router';
import axios from '@/plugins/axios';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

const router = useRouter();
const query = router.currentRoute.value.query;

dayjs.extend(utc);

const params = reactive({
  'alert_id': query.id,
  'version': query.v,
});

const isLoading = ref(true);
const isError = ref(false);
const errorMessage = ref({});

const worksites = ref({});
const getWorksites = async () => {
  axios.get('/api/v2/globalalert/worksites/get', {params: params}).then((response) => {
    worksites.value = response.data.result_data;
    isLoading.value = false;
  }).catch((error) => {
    if (error.response.status !== 400) {
      router.push({name: 'forbidden'});
    }

    isLoading.value = false;
    isError.value = true;
    errorMessage.value = error.response.data.err_message_list ?? {};
  });
};

getWorksites();

const worksitesDatetime = (datetime) => {
  return dayjs(datetime).utc().format('YYYY-MM-DD HH:mm:ss');
};
</script>
