import axios from 'axios';
import {useCookies} from 'vue3-cookies';

const axios_instance = axios.create();
const {cookies} = useCookies();
axios_instance.interceptors.request.use(config => {
  let auth = 'dummy';
  for (let key of cookies.keys()) {
    if (key.match(/^wniauth/)) {
      auth = key;
      break;
    }
  }

  config.headers = {
    'ApiAuthorization': 'Bearer ' + btoa(auth),
  };
  return config;
});

export default axios_instance;
