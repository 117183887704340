import {useCookies} from 'vue3-cookies';

const {cookies} = useCookies();

function getFetchAuthHeader(headers = {}) {
  let auth = 'dummy';
  for (let key of cookies.keys()) {
    if (key.match(/^wniauth/)) {
      auth = key;
      break;
    }
  }
  headers['ApiAuthorization'] = 'Bearer ' + btoa(auth);
  return headers;
}

export {getFetchAuthHeader};
