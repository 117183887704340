import {createRouter, createWebHistory} from 'vue-router';
import AdminMail from '@/components/Admin/Mail';
import AddonGlobalAlert from '@/components/Addon/GlobalAlert';
import AddonGlobalAlertSetting from '@/components/Addon/GlobalAlertSetting';
import AddonGlobalAlertWorksite from '@/components/Addon/GlobalAlertWorksite';
import ErrorNotFound from '@/components/Error/NotFound';
import ErrorForbidden from '@/components/Error/Forbidden';

const DEFAULT_TITLE = 'SORA レジリエンス';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/admin/mail',
      name: 'mail',
      component: AdminMail,
      meta: {title: 'メール履歴 | SORA レジリエンス'},
    },

    {
      path: '/addon/globalalert',
      name: 'globalalert',
      component: AddonGlobalAlert,
      meta: {title: 'グローバルアラート一覧 | SORA レジリエンス'},
    },

    {
      path: '/addon/globalalert/setting',
      name: 'globalalertSetting',
      component: AddonGlobalAlertSetting,
      meta: {title: 'グローバルアラート配信設定 | SORA レジリエンス'},
    },

    {
      path: '/addon/globalalert/worksite',
      name: 'globalalertWorksite',
      component: AddonGlobalAlertWorksite,
      meta: {title: '影響拠点一覧 | SORA レジリエンス'},
    },

    {
      path: '/403',
      name: 'forbidden',
      component: ErrorForbidden,
      meta: {title: 'Forbidden'},
    },

    {
      path: '/:catchAll(.*)',
      component: ErrorNotFound,
      meta: {title: 'Not Found'},
    },
  ],
});

router.afterEach((to) => {
  document.title = to.meta.title || DEFAULT_TITLE;
});

export default router;
