import {createApp} from 'vue';
import App from './App.vue';
import router from './router.js';

import "bootstrap";
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/solid.css';

let app = createApp(App);
app.use(router);
app.mount('#app');
