<template>
  <article class="mainContents adminMainContent__scroll bg-white">
    <div class="w-100">
      <h1 class="text-primary mb-5 ">
        <i class="fa-solid fa-envelope"></i> アラートメール配信履歴管理
        <div class="row border-bottom border-primary"></div>
      </h1>

      <div class="block">
        <div>
          <div class="d-flex justify-content-end mb-2 mb-md-3 pe-4">
            <div class="col-auto">
              <button type="button" class="btn btn-primary btn-lg" @click="getCsv" :disabled="count < 1 || count > 10000">
                <span> CSV出力</span>
              </button>
            </div>
          </div>
        </div>
        <header class="DataList__header">
          <h2 class="DataList__title text-secondary"> アラートメール配信履歴一覧 </h2>
        </header>
        <div class="p-4">
          <metrics>
          </metrics>
        </div>
        <div class="p-4 pb-0">
          <div class="d-flex align-items-sm-center">
            <p class="input-title">メール発信日時</p>
            <div class="col-auto ms-4">
              <div class="input-group border rounded-pill">
                <span class="input-group-text border-0 bg-transparent">
                  <i class="fa-solid fa-calendar"></i>
                </span>
                <input type="datetime-local" placeholder="メール発信日時" aria-label="datetime-local"
                       v-model="params.send_time_from"
                       class="form-control form-control-lg border-0 bg-transparent rounded-pill form-text">
              </div>
            </div>
            <p class="input-title mx-3">〜</p>
            <div class="col-auto">
              <div class="input-group border rounded-pill">
                <span class="input-group-text border-0 bg-transparent">
                  <i class="fa-solid fa-calendar"></i>
                </span>
                <input placeholder="メール発信日時" aria-label="datetime-local" type="datetime-local"
                       v-model="params.send_time_to"
                       class="form-control form-control-lg border-0 bg-transparent rounded-pill form-text">
              </div>
            </div>
            <p class="input-title ms-5">アラート種類</p>
            <div class="col-auto ms-4">
              <div class="input-group border rounded-pill">
                <select v-model="params.alert_type"
                        class="form-control form-control-lg border-0 bg-transparent rounded-pill form-text form-select">
                  <option value="">すべて</option>
                  <option v-for="(value, key) in alertList" :key="key" :value="key">{{ value }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="p-4">
          <div class="d-flex mb-3 align-items-sm-center">
            <p class="input-title">検索</p>
            <div class="col-auto ms-4">
              <div class="input-group border rounded-pill">
                <span class="input-group-text border-0 bg-transparent"><i class="fa-solid fa-magnifying-glass"></i></span>
                <input type="search" placeholder="企業ID" aria-label="search"
                       v-model="params.customer_id"
                       maxlength="100"
                       class="form-control form-control-lg border-0 bg-transparent rounded-pill form-text"
                       @keyup.enter="searchHistory">
              </div>
            </div>

            <div class="col-auto ms-4">
              <div class="input-group border rounded-pill">
                <span class="input-group-text border-0 bg-transparent"><i class="fa-solid fa-magnifying-glass"></i></span>
                <input type="search" placeholder="メールアドレス" aria-label="search"
                       v-model="params.mail_to"
                       maxlength="100"
                       class="form-control form-control-lg border-0 bg-transparent rounded-pill form-text"
                       @keyup.enter="searchHistory">
              </div>
            </div>

            <div class="col-auto ms-4">
              <div class="input-group border rounded-pill">
                <span class="input-group-text border-0 bg-transparent"><i class="fa-solid fa-magnifying-glass"></i></span>
                <input type="search" placeholder="キーワード" aria-label="search"
                       v-model="params.search_keyword"
                       maxlength="100"
                       class="form-control form-control-lg border-0 bg-transparent rounded-pill form-text"
                       @keyup.enter="searchHistory">
              </div>
            </div>
          </div>
        </div>

        <div class="p-4 pt-0">
          <div class="d-flex mb-3 align-items-sm-center">
            <div class="col-auto">
              <button type="button" class="btn btn-primary btn-lg btn-114" @click="pushSearch">
                <span>検索</span>
              </button>
              <button type="button" class="btn btn-outline-primary btn-lg btn-114" @click="resetForm" ref="resetButton">
                <span>クリア</span>
              </button>
            </div>
            <div class="col-auto align-self-center mx-4 text-primary fw-bold" v-if="isSearch">
              <i class="fa-solid fa-spinner fa-spin"></i> 検索中
            </div>
            <div class="col-auto align-self-center mx-4 text-primary fw-bold" v-else>
              {{ count }}件 / {{ total_count }}件
            </div>
          </div>
        </div>

        <div class="p-4 pt-0" v-if="isError">
          <div class="alert" :class="errorClass">
            {{ errorMessage }}
          </div>
        </div>

        <div class="table-responsive overflow-auto shadowbox" v-if="count > 0">
          <table class="table table-striped table-hover align-middle mb-0 table_sticky">
            <thead class="text-secondary font-weight-bolder text-nowrap">
            <tr>
              <th></th>
              <th>
                メール発信日時
                <sort-icon item="send_time"></sort-icon>
              </th>
              <th>
                種類
                <sort-icon item="alert_name"></sort-icon>
              </th>
              <th>
                企業ID
                <sort-icon item="customer_id"></sort-icon>
              </th>
              <th>
                メールアドレス
                <sort-icon item="mail_to"></sort-icon>
              </th>
              <th>
                メール件名
                <sort-icon item="mail_subject"></sort-icon>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(row, index) in list" :key="index">
              <td class="text-center text-black-50"><span>{{ (30 * (params.page - 1)) + index + 1 }}</span></td>
              <td class="text-truncate mw-75" data-bs-toggle="tooltip" data-bs-placement="bottom">
                {{ row.send_time }}
              </td>
              <td class="text-truncate mw-150" data-bs-toggle="tooltip" data-bs-placement="bottom">
                {{ row.alert_name }}
              </td>
              <td class="text-truncate mw-150" data-bs-toggle="tooltip" data-bs-placement="bottom">
                {{ row.customer_id }}
              </td>
              <td class="text-truncate mw-150" data-bs-toggle="tooltip" data-bs-placement="bottom">
                {{ row.mail_to }}
              </td>
              <td class="text-truncate mw-250" data-bs-toggle="tooltip" data-bs-placement="bottom">
                <button class="table-link" @click="openModal(row.mail_body)">
                  {{ row.mail_subject }}
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <div class="p-4" v-if="count > 0">
          <div class="row border-top mb-3"></div>
          <div class="d-flex justify-content-center">
            <paginate
                v-model="params.page"
                :page-count="total_page"
                :click-handler="clickPage"
                :margin-pages="2"
                :prev-text="'<'"
                :next-text="'>'"
            ></paginate>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isShowModal">
      <div id="modal" class="modal show d-block" tabindex="-1"
           aria-labelledby="modal" :aria-hidden="!isShowModal"
           @click.self="isShowModal=false">
        <div class="modal-dialog modal-dialog-centered mail-modal">
          <div class="modal-content">
            <div class="mail-modal-header">
              <h4 id="modalLabel" class="modal-title text-secondary">内容</h4>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="isShowModal=false"></button>
            </div>
            <div class="mail-modal-body">
              <p class="mail-modal-text">{{ modal_body }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-backdrop show"></div>
    </div>

  </article>
</template>

<script>
import Paginate from 'vuejs-paginate-next';
import Metrics from '@/components/Admin/Mail/Metrics';
import SortIcon from '@/components/Admin/Mail/SortIcon';
import axios from '@/plugins/axios';
import {alert_list} from '@/plugins/alert_type';
import {reactive, ref, onMounted, provide} from 'vue';
import {useRouter} from 'vue-router';
import * as dayjs from 'dayjs';

export default {
  name: 'AdminMail',
  components: {
    Metrics,
    SortIcon,
    Paginate,
  },

  setup() {
    const today = dayjs();
    const params = reactive({
      'page': 1,
      'send_time_from': today.format('YYYY-MM-DDT00:00'),
      'send_time_to': null,
      'alert_type': '',
      'customer_id': null,
      'mail_to': null,
      'search_keyword': null,
      'sort_key': 'send_time',
      'sort_order': 'desc',
    });

    const alertList = alert_list();
    const isSearch = ref(false);
    const isShowModal = ref(false);
    const modal_body = ref('');

    const count = ref(0);
    const total_count = ref(0);
    const list = ref([]);
    const total_page = ref(0);

    const isError = ref(false);
    const errorClass = ref('');
    const errorMessage = ref('');
    const resetButton = ref(null);

    const router = useRouter();

    const searchHistory = async () => {
      isSearch.value = true;
      isError.value = false;
      axios.get('/api/admin/mail/history', {params: params}).then((response) => {
        count.value = response.data.results.count;
        total_count.value = response.data.results.total_count;
        params.page = response.data.results.page;
        total_page.value = response.data.results.total_page;
        list.value = response.data.results.list;
      }).catch((error) => {
        if (error.response.status === 403) {
          router.push({name: 'forbidden'});
          return;
        }

        isError.value = true;
        count.value = 0;
        if (error.response.status >= 500) {
          errorClass.value = 'alert-danger';
          errorMessage.value = error.response.data.message;
        } else {
          errorClass.value = 'alert-warning';
          errorMessage.value = error.response.data.message;
        }
      }).finally(() => {
        isSearch.value = false;
      });
    };

    const getCsv = () => {
      isError.value = false;
      axios.get('/api/admin/mail/history/csv', {params: params}).then((response) => {
        const bom = '\uFEFF';
        let blob = new Blob([bom, response.data], {'type': 'text/csv'});
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'alert_mail_delivery_history_' + today.format('YYYYMMDD') + '.csv';
        link.click();
      }).catch(error => {
        if (error.response.status === 403) {
          router.push({name: 'forbidden'});
        }

        isError.value = true;
        if (error.response.status >= 500) {
          errorClass.value = 'alert-danger';
          errorMessage.value = error.response.data.message;
        } else {
          errorClass.value = 'alert-warning';
          errorMessage.value = error.response.data.message;
        }
      }).finally(() => {
        isSearch.value = false;
      });
    };

    const pushSearch = () => {
      params.page = 1;
      searchHistory();
    };

    const resetForm = () => {
      params.send_time_from = null;
      params.send_time_to = null;
      params.alert_type = '';
      params.customer_id = null;
      params.mail_to = null;
      params.search_keyword = null;
      params.page = 1;
      resetButton.value.blur();
    };

    const updateSortKey = (value) => {
      params.sort_key = value;
      params.page = 1;
      searchHistory();
    };

    const updateSortOrder = (value) => {
      params.sort_order = value;
      params.page = 1;
      searchHistory();
    };

    const clickPage = () => {
      searchHistory();
    };

    const openModal = (body) => {
      modal_body.value = body;
      isShowModal.value = true;
    };

    onMounted(() => {
      searchHistory();
    });

    provide('params', params);
    provide('updateSortKey', updateSortKey);
    provide('updateSortOrder', updateSortOrder);

    return {
      params,
      list,
      count,
      total_count,
      total_page,
      searchHistory,
      getCsv,
      resetForm,
      isSearch,
      alertList,
      clickPage,
      openModal,
      isShowModal,
      modal_body,
      isError,
      errorMessage,
      errorClass,
      pushSearch,
      resetButton,
    };

  },
};
</script>

<style scoped>

.support__layers > :not(:last-child) {
  margin-bottom: 20px
}

.support__layers > :last-child {
  margin-bottom: 40px
}

.alias_worksite > :last-child {
  margin-bottom: 40px
}

.DataList__table tbody tr td:first-child {
  border: 1px solid #006;
  background: #5ca2ec;
  padding: 2px 10px
}

ul.history_info ul li {
  display: inline
}

ul.history_info ul li:nth-child(1) {
  padding: 0 10px 0 0
}

ul.history_info ul li:nth-child(2) {
  padding: 0 15px 0 0
}

ul.history_info ul {
  display: inline
}

table.DataList__table tbody tr td:nth-child(2) input {
  width: 100%
}

.modal {
  display: block;
}

.mail-modal-text {
  white-space: pre-line;
}

.table-link {
  color: rgba(194, 171, 135) !important;
}

</style>
