const alerts = {
  'LAT001': '避難情報',
  'LAT002': '停電発生状況',
  'JMA002': '暴風雪警報',
  'JMA003': '大雨警報',
  'JMA004': '洪水警報',
  'JMA005': '暴風警報',
  'JMA006': '大雪警報',
  'JMA007': '波浪警報',
  'JMA008': '高潮警報',
  'JMA019': '高潮注意報',
  'JMA032': '暴風雪特別警報',
  'JMA033': '大雨特別警報',
  'JMA035': '暴風特別警報',
  'JMA036': '大雪特別警報',
  'JMA037': '波浪特別警報',
  'JMA038': '高潮特別警報',
  'JMA101': '土砂災害警戒情報',
  'JMA201': '土砂キキクル',
  'JMA202': '浸水キキクル',
  'JMA203': '洪水キキクル',
  'JMA301': '氾濫警戒情報',
  'JMA302': '氾濫危険情報',
  'JMA303': '氾濫発生情報',
  'JMA401': '震源・震度に関する情報',
  'WNI001': '土砂災害リスク',
  'WNI002': '浸水リスク',
  'WNI003': '強風リスク',
  'WNI004': '強雨リスク',
  'WNI005': '強雪リスク',
  'WNI006': 'リスクサマリー',
  'WNI007': '停電リスク',
  'WNI008': '落雷リスク',
  'WNI009': 'グローバル地震情報',
  'GLB001': 'グローバルアラート',
};

const alert_name = (type) => {
  if (type in alerts) {
    return alerts[type];
  }
  return null;
};

const alert_list = () => {
  return alerts;
};

export {alert_list, alert_name};
