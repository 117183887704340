<template>
  <span class="ms-2" @click="clickSort"><i class="fa-solid" :class="getSort"></i></span>
</template>

<script>
import {inject, computed} from 'vue';

export default {
  name: 'SortIcon',

  props: {
    item: {type: String, required: true},
  },

  setup(props) {
    const params = inject('params');
    const updateSortKey = inject('updateSortKey');
    const updateSortOrder = inject('updateSortOrder');

    const getSort = computed(() => {
      if (props.item === params.sort_key) {
        if (params.sort_order === 'desc') {
          return {'fa-sort-down': true};
        } else {
          return {'fa-sort-up': true};
        }
      } else {
        return {'fa-sort': true};
      }
    });

    const clickSort = () => {
      if (props.item === params.sort_key) {
        if (params.sort_order === 'desc') {
          updateSortOrder('asc');
        } else {
          updateSortOrder('desc');
        }
      } else {
        updateSortKey(props.item);
        updateSortOrder('asc');
      }
    };

    return {
      getSort,
      clickSort,
      updateSortKey,
      updateSortOrder,
    };
  },
};
</script>

<style scoped>

</style>
