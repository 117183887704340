<template>
  <table class="table table-striped table-hover align-middle mb-0 w-auto">
    <thead class="text-secondary font-weight-bolder text-nowrap">
    <tr>
      <th class="text-center"> 期間</th>
      <th class="text-center"> 送信数</th>
      <th class="text-center"> エラー数</th>
      <th class="text-center"> エラー率</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td class="text-truncate minw-120" data-bs-toggle="tooltip" data-bs-placement="bottom" title="過去1ヶ月">過去1ヶ月(30日)</td>
      <td class="text-truncate minw-95 text-center" data-bs-toggle="tooltip" data-bs-placement="bottom" :title="metrics.sent_in_the_past_month">{{ metrics.sent_in_the_past_month }}</td>
      <td class="text-truncate minw-95 text-center" data-bs-toggle="tooltip" data-bs-placement="bottom" :title="metrics.errors_in_the_past_month">{{ metrics.errors_in_the_past_month }}</td>
      <td class="text-truncate minw-95 text-center" data-bs-toggle="tooltip" data-bs-placement="bottom" :title="metrics.error_rate_for_the_past_month + '%'">{{ metrics.error_rate_for_the_past_month }}%</td>
    </tr>
    <tr>
      <td class="text-truncate minw-120" data-bs-toggle="tooltip" data-bs-placement="bottom" title="過去1週間">過去1週間</td>
      <td class="text-truncate minw-95 text-center" data-bs-toggle="tooltip" data-bs-placement="bottom" :title="metrics.sent_in_the_past_week">{{ metrics.sent_in_the_past_week }}</td>
      <td class="text-truncate minw-95 text-center" data-bs-toggle="tooltip" data-bs-placement="bottom" :title="metrics.errors_in_the_past_week">{{ metrics.errors_in_the_past_week }}</td>
      <td class="text-truncate minw-95 text-center" data-bs-toggle="tooltip" data-bs-placement="bottom" :title="metrics.error_rate_for_the_past_week + '%'">{{ metrics.error_rate_for_the_past_week }}%</td>
    </tr>
    <tr>
      <td class="text-truncate minw-120" data-bs-toggle="tooltip" data-bs-placement="bottom" title="過去24時間">過去24時間</td>
      <td class="text-truncate minw-95 text-center" data-bs-toggle="tooltip" data-bs-placement="bottom" :title="metrics.sent_in_the_last_24_hours">{{ metrics.sent_in_the_last_24_hours }}</td>
      <td class="text-truncate minw-95 text-center" data-bs-toggle="tooltip" data-bs-placement="bottom" :title="metrics.errors_in_the_last_24_hours">{{ metrics.errors_in_the_last_24_hours }}</td>
      <td class="text-truncate minw-95 text-center" data-bs-toggle="tooltip" data-bs-placement="bottom" :title="metrics.error_rate_for_the_last_24_hours + '%'">{{ metrics.error_rate_for_the_last_24_hours }}%</td>
    </tr>
    </tbody>
  </table>
</template>

<script>

import {reactive, onMounted} from 'vue';
import axios from '@/plugins/axios';

export default {
  name: 'AdminMailMetrics',

  setup() {
    const metrics = reactive({
      'sent_in_the_past_month': 0,
      'errors_in_the_past_month': 0,
      'error_rate_for_the_past_month': 0,
      'sent_in_the_past_week': 0,
      'errors_in_the_past_week': 0,
      'error_rate_for_the_past_week': 0,
      'sent_in_the_last_24_hours': 0,
      'errors_in_the_last_24_hours': 0,
      'error_rate_for_the_last_24_hours': 0,
    });

    const getMetrics = async () => {
      axios.get('/api/admin/mail/metrics').then((response) => {
        metrics.sent_in_the_past_month = response.data.results.sent_in_the_past_month;
        metrics.errors_in_the_past_month = response.data.results.errors_in_the_past_month;
        metrics.error_rate_for_the_past_month = response.data.results.error_rate_for_the_past_month;
        metrics.sent_in_the_past_week = response.data.results.sent_in_the_past_week;
        metrics.errors_in_the_past_week = response.data.results.errors_in_the_past_week;
        metrics.error_rate_for_the_past_week = response.data.results.error_rate_for_the_past_week;
        metrics.sent_in_the_last_24_hours = response.data.results.sent_in_the_last_24_hours;
        metrics.errors_in_the_last_24_hours = response.data.results.errors_in_the_last_24_hours;
        metrics.error_rate_for_the_last_24_hours = response.data.results.error_rate_for_the_last_24_hours;
      });
    };

    onMounted(getMetrics);

    return {
      metrics,
    };
  },
};
</script>

<style scoped>

</style>
