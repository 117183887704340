<template>
    <article class="mainContents adminMainContent__scroll bg-white">
        <div class="w-100">
            <div class="block">
                <header class="DataList__header">
                    <div class="globalAlert__heading">
                        <h2 class="text-secondary">
                            <span>グローバルアラート配信設定</span>
                            <span class="ms-3 text-secondary-small">Global Alert Email Settings</span>
                        </h2>
                    </div>
                    <div class="justify-content-between d-flex align-items-end">
                        <div>
                            <p class="globalAlert__title text-secondary">
                                メール通知するグローバルアラートの条件を設定します。</p>
                            <p class="small text-secondary">
                                Set conditions for email notification of global alert.
                            </p>
                        </div>
                    </div>
                </header>
                <div class="search">
                    <div class="search__item checkbox">
                        <h3 class="fw-semibold">重大度（Severity）</h3>
                        <div class="mt-2 d-flex flex-wrap">
                            <template v-for="(row, index) in severityLists" :key="index">
                                <label class="me-2">
                                    <input v-model="params.severity_id" name="severity_id[]" class="form-check-input" type="checkbox"
                                           :value="row.severity_id">
                                    <span class="small me-1">{{ row.severity_name_ja }}（{{ row.severity_name_en }}）</span>
                                </label>
                            </template>
                        </div>
                    </div>
                    <div class="search__item checkbox search__item-mt">
                        <h3 class="fw-semibold">カテゴリ（Category）</h3>
                        <div class="mt-2 d-flex flex-wrap">
                            <template v-for="(row, index) in categoryLists" :key="index">
                                <label class="me-2">
                                    <input v-model="params.category_id" name="category_id[]" class="form-check-input" type="checkbox"
                                           :value="row.category_id">
                                    <span class="small me-1">
                                        {{ row.category_name_ja }}（{{ row.category_name_en }}）
                                    </span>
                                </label>
                            </template>
                        </div>
                    </div>
                    <div class="search__item d-flex justify-content-between w-100 align-items-sm-end search__item-mt p-4 pt-0">
                        <div class="d-flex justify-content-end pe-4">
                            <button type="button" @click="updateSetting" class="btn btn-primary btn-lg me-3">
                                <span>更新（Update）</span>
                            </button>
                            <button type="button" @click="navigateToGlobalalert" class="btn btn-outline-primary btn-lg w-auto">
                                <span>キャンセル（Cancel）</span>
                            </button>
                        </div>
                    </div>
                </div>

                <div v-if="isError" class="mt-5">
                    <div :class="errorClass">
                        <div v-if="errorMessage.ja">
                            <span>{{ errorMessage.ja }}</span><br>
                            <span class="en small">{{ errorMessage.en }}</span>
                        </div>
                        <div v-else>
                            <span>システムエラーが発生しました。</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </article>
</template>
<script setup>
import {reactive, ref} from 'vue';
import axios from '@/plugins/axios';
import {useRouter} from 'vue-router';

const router = useRouter();

const params = reactive({
  severity_id: [],
  category_id: [],
});

const isSearch = ref(false);
const isError = ref(false);
const errorClass = ref('');
const errorMessage = ref({});

const severityLists = ref([]);
const categoryLists = ref([]);
const getSetting = async () => {
  isSearch.value = true;
  isError.value = false;
  axios.get('/api/v2/globalalert/setting/get').then((response) => {
    const severity_list = response.data.result_data?.severity_list ?? [];
    severityLists.value = severity_list.sort((a, b) => parseInt(b.severity_id) - parseInt(a.severity_id));
    categoryLists.value = response.data.result_data.category_list ?? [];

    params.severity_id = severityLists.value.filter(item => item.selected).map(item => item.severity_id)
    params.category_id = categoryLists.value.filter(item => item.selected).map(item => item.category_id)
  }).catch((error) => {
    if (error.response.status === 403) {
      router.push({name: 'forbidden'});
      return;
    }

    isError.value = true;
    if (error.response.status >= 500) {
      errorClass.value = 'alert-danger';
      errorMessage.value = error.response.data.err_message_list ?? {};
    } else {
      errorClass.value = 'alert-warning';
      errorMessage.value = error.response.data.err_message_list ?? {};
    }
  }).finally(() => {
    isSearch.value = false;
  });
};

const updateSetting = async () => {
  isSearch.value = true;
  isError.value = false;
  axios.post('/api/v2/globalalert/setting/update', params).then(() => {
    router.push('/addon/globalalert');
  }).catch((error) => {
    if (error.response.status === 403) {
      router.push({name: 'forbidden'});
      return;
    }

    isError.value = true;
    if (error.response.status >= 500) {
      errorClass.value = 'alert-danger';
      errorMessage.value = error.response.data.err_message_list ?? {};
    } else {
      errorClass.value = 'alert-warning';
      errorMessage.value = error.response.data.err_message_list ?? {};
    }
  }).finally(() => {
    isSearch.value = false;
  });
};

const navigateToGlobalalert = () => {
  router.push('/addon/globalalert');
};

getSetting();
</script>
